import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    category: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post('https://api.scouts121.be/contactform', formData)
      .then((response) => {
        alert('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
          category: '',
        });
      })
      .catch((error) => {
        alert('Failed to send message.');
        console.error('There was an error!', error);
      });
  };

  // Inline styles
  const formStyle = {
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
  };

  const inputStyle = {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };

  const textareaStyle = {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    minHeight: '100px',
  };

  const selectStyle = {
    display: 'block',
    width: '100%',
    padding: '8px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h2>Contacteer ons</h2>
      <div className="textelement">
        <h1>e-mailadressen</h1>
        <h3>groepsleiding</h3>
        <p>groepsleiding@scouts121.be</p>
        <h3>verhuur</h3>
        <p>verhuur@scouts121.be</p>
        <h3>kapoenen</h3>
        <p>kapoenen@scouts121.be</p>
        <h3>welka's</h3>
        <p>welkas@scouts121.be</p>
        <h3>jogi's</h3>
        <p>jogis@scouts121.be</p>
        <h3>givers</h3>
        <p>givers@scouts121.be</p>
        <h3>jins</h3>
        <p>jins@scouts121.be</p>
      </div>
      
    </div>
  );
};

export default ContactForm;
